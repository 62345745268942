<template>
  <!-- header-section start -->
  <header class="header-section w-100">
    <div class="py-sm-6 py-3 mx-xxl-20 mx-md-15 mx-3">
      <div class="d-flex align-items-center gap-xxl-10 gap-lg-8 w-100">
        <nav
          class="navbar-custom d-flex gap-lg-6 flex-column flex-lg-row w-100"
        >
          <div
            class="top-bar w-100 d-flex justify-content-between gap-lg-0 gap-6"
          >
            <div>
              <a class="navbar-brand d-flex align-items-center gap-4" href="/">
                <img
                  class="w-100 logo1 d-lg-none d-xl-none d-sm-block d-md-none"
                  src="assets/logo/PUBGM x WOW.png"
                  alt="favicon"
                />
                <img
                  class="logo2"
                  src="assets/logo/PUBGM x WOW.png"
                  alt="logo"
                  
                />
              </a>
            </div>
            <div>
              <!--  <a class="navbar-brand d-flex align-items-center gap-4" href="/">                          
                            <img class="w-100 logo1 d-lg-none d-xl-none d-sm-block d-md-none"  src="assets/logo/wow.png" alt="favicon">
                            <img class="logo2" src="assets/logo/wow.png" alt="logo" style="width:90%">
                        </a> -->

              <button
                class="rounded-rect-button"
                @click="showLoginAlert"           >
                <i class="ti ti-user"></i> Login
              </button><br />
             <!--  <a style="color:silver" @click="redirectToRegistration"><span>Not Signedup yet?</span></a> -->
            </div>
          </div>
        </nav>
      </div>
    </div>
  </header>
</template>

<script>
import { useRouter } from 'vue-router';

export default {
  name: "HeaderView",
  setup() {
  const router = useRouter();
  //console.log(router); // This should log the router instance

  const redirectToRegistration = () => {
    //console.log('Attempting to route'); // Check if this logs when button is clicked
    router.push({ name: "Registration" }).catch(err => {
      console.error(err); // Catch and log any routing errors
    });
  };

  return { redirectToRegistration };
},
methods: {
    showLoginAlert() {
      //alert('Check back again on the launch date i.e. 17th May 2024.'); // Display alert message
     // window.location.href = 'https://www.lms.pubgmwow.pk';
     window.location.href = 'https://www.lms.pubgmwow.pk/';
    }
  }
};
</script>

<style scoped>
.btn-rounded-cus {
  background-color: rgb(18 55 238) !important;
}
.circlebtn {
  background-color: rgb(18 55 238) !important;
}

.top-bar {
  padding: 10px; /* Add padding for spacing */
}

.logo1,
.logo2 {
  height: 50px !important; /* Adjust the height of the logo */
  /* You can set a fixed height for the logo to ensure consistent size */
}

.rounded-rect-button {
  display: inline-block;
  padding: 8px 16px; /* Adjust padding as needed */
  border-radius: 12px; /* Rounded corners */
  border: 2px solid #007bff; /* Border color and thickness */
  background-color: #007bff; /* Background color */
  color: #ffffff; /* Text color */
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-decoration: none; /* Remove underline from anchor */
  cursor: pointer; /* Show pointer cursor on hover */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Box shadow for 3D effect */
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  background-color: rgb(251, 204, 4);
  font-weight: bold;
  color: black;
  height: 40px;
}

/* Hover effect */
.rounded-rect-button:hover {
  transform: translateY(-2px); /* Move button up slightly on hover */
}
</style>
