//app.vue

<template>
  <!-- Preloader -->
  <div class="preloader">
        <div class="loader">
            <span></span>
        </div>
    </div>

    <!-- cursor effect-->
    <div class="cursor"></div>
  <HeaderView />
  <router-view></router-view>

  <br /> <br />
  <CallToAction />

</template>

<script>
import HeaderView from './components/HeaderView.vue';
//import HomeView from './components/HomeView.vue';

//import SwiperView from './components/SwiperView.vue';
//import PlayerView from './components/PlayerView.vue';
//import GameView from './components/GameView.vue';
import CallToAction from './components/CallToAction.vue';
//import RegistrationView from './components/RegistrationView.vue';


export default {
  name: 'App',
  components: {
    HeaderView,
    
//PlayerView,
    //GameView,
    CallToAction,
  //  RegistrationView
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.heading{
  color:white;
}
</style>
