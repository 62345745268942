<template>
      <NotificationArea />
  <WalletView />
  <AccountView />
  <hr style="color: white;border-style: solid; border-width: 2px 0;" />

  <SliderView />
  <hr style="color: white;border-style: solid; border-width: 2px 0;" />
  <div class="mt-2 mb-2 heading" >
    <img src="assets/regClose.png" alt="registration close" style="width: 100%; height: 50%;" />
  </div> 
  <hr style="color: white;border-style: solid; border-width: 2px 0;" />
<h1 style="color: rgb(251,204,4); border:2px" >WHAT IS <br /> WOW CERTIFICATE</h1>

  <hr style="color: white;border-style: solid; border-width: 2px 0;" />

  <div>
    <video style="width:95%; border:5px solid #525252; border-radius:15px; padding:1px; margin:0 auto;"  controls loop autoplay playsinline >
        <source src="https://d2dc70bwwfynif.cloudfront.net/PUBG%20x%20HEC%20RENDER.mp4" type="video/mp4">
        Your browser does not support the video tag.
    </video>
  </div>
 <!--  <PlayerView /> -->

 <hr style="color: white;border-style: solid; border-width: 2px 0;" />
  <BenefitsView />
</template>

<script>
import NotificationArea from '../components/NotificationArea.vue';
import WalletView from '../components/WalletView.vue';
import AccountView from '../components/AccountView.vue';
import SliderView from '../components/SliderView.vue';
import BenefitsView from '../components/BenefitsView.vue';

export default {
  name: 'App',
  components: {
    NotificationArea,
    WalletView,
    AccountView,
    SliderView,
  BenefitsView,
  }
}
</script>