<template>
  <!-- Hero Section start  -->
  <section class="pt-110 pb-110 position-relative">
    <div class="carousel slide abc" data-ride="carousel">
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img
            class="d-block w-100"
            src="assets/slider/1.jpg"
            alt="First slide"
          />
          <div class="carousel-caption d-md-block">
            <!--                     <button class="btn-half-border reg-now-btn position-relative d-inline-block py-2 px-6 bgp-1 rounded-pill">Regsiter Now</button>
 -->
          <!--   <input
              type="button"
              class="reg-now-btn"
              alt="Register Now"
              value="Register Now"
              @click="redirectToRegistration"
            /> -->
          </div>
        </div>
        <div class="carousel-item">
          <img
            class="d-block w-100"
            src="assets/slider/2.jpg"
            alt="Second slide"
          />
          <div class="carousel-caption d-md-block">
            <!--                     <input type="image" src="assets/img/new/Register Now Button.png" class="reg-now-btn position-relative d-inline-block py-2 px-6 rounded-pill" alt="Submit" style="width:60%;height:50%">
 -->
           <!--  <input
              type="button"
              class="reg-now-btn"
              alt="Register Now"
              value="Register Now"
              @click="redirectToRegistration"
            /> -->
          </div>
        </div>
        <div class="carousel-item">
          <img
            class="d-block w-100"
            src="assets/slider/3.jpg"
            alt="Third slide"
          />
          <div class="carousel-caption d-md-block">
           <!--  <input
              type="button"
              class="reg-now-btn"
              @click="redirectToRegistration"
              alt="Register Now"
              value="Register Now"
            /> -->
          </div>
        </div>
        <div class="carousel-item">
          <img
            class="d-block w-100"
            src="assets/slider/4.jpg"
            alt="Third slide"
          />
          <div class="carousel-caption d-md-block">
          <!--   <input
              type="button"
              class="reg-now-btn"
              @click="redirectToRegistration"
              alt="Register Now"
              value="Register Now"
            /> -->
          </div>
        </div>
        <div class="carousel-item">
          <img
            class="d-block w-100"
            src="assets/slider/5.jpg"
            alt="Third slide"
          />
          <div class="carousel-caption d-md-block">
            <!-- <input
              type="button"
              class="reg-now-btn"
              @click="redirectToRegistration"
              alt="Register Now"
              value="Register Now"
            /> -->
          </div>
        </div>
      </div>
      <!--   <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
        </a> -->
    </div>
  </section>
  <!-- Hero Section end  -->
</template>

<script>
import { useRouter } from 'vue-router';
export default {
  name: "SliderView",
  setup() {
  const router = useRouter();
  //console.log(router); // This should log the router instance

  const redirectToRegistration = () => {
    //console.log('Attempting to route'); // Check if this logs when button is clicked
    router.push({ name: "Registration" }).catch(err => {
      console.error(err); // Catch and log any routing errors
    });
  };

  return { redirectToRegistration };
},
};
</script>


<style scoped>
.reg-now-btn {
  width: 60%;
  height: 50%;
  padding: 10px 20px; /* Adjust padding as needed */
  border-radius: 30px; /* Rounded corners */
  border: none; /* Remove default border */
  cursor: pointer; /* Show pointer cursor on hover */
  background: linear-gradient(135deg, #f6d365 0%, #fda085 100%); /* Gradient background */
  font-weight: bold;
  color: white;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Box shadow for depth */
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out, background 0.3s ease-in-out;
}

/* Hover effect */
.reg-now-btn:hover {
  transform: scale(1.05); /* Scale up slightly on hover */
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.2); /* Increase box shadow on hover */
  background: linear-gradient(135deg, #fda085 0%, #f6d365 100%); /* Invert gradient on hover */
}

/* Active effect */
.reg-now-btn:active {
  transform: scale(0.95); /* Scale down slightly on click */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Reset box shadow on click */
}
</style>

